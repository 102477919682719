.suggestion {
	display: flex;
	align-items: center;
	padding: 0.4rem;
	color: var(--darkgrey);
	cursor: pointer;
}

.suggestion.active {
	background: #f5f5f5;
}

.input {
	width: 100%;
	padding: 15px 12px;
	font-size: 1.2rem;
	border: 1px solid #e5e5e5;
	border-bottom: 2px solid #ddd;
	background: #f2f2f2;
	color: #363636;
	z-index: 99;
}

.input::placeholder {
	color: #b0bec5;
}

.container {
	--input-height: 45px;
	width: 100%;
	position: relative;
}

.suggestion_container {
	position: absolute;
	z-index: 1;
	top: var(--input-height);
	margin-top: -2px;
	border-radius: 2px;
	background: white;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
	/* border: 1px solid #e5e5e5; */
	/* border-bottom: 2px solid #ddd; */
}
